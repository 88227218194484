/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import configData from "../config.json";
import authHeader from './auth-header';

const API_URL = configData.REACT_APP_API_URL;
const GAME_BEFOREPLAY_URL = `${API_URL}/game/beforePlay`
const GAME_CHECKOUT_URL = `${API_URL}/game/checkout`
const GAME_GETCREDIT_URL = `${API_URL}/game/getCredit`
const GAME_LIST_URL = `${API_URL}/game/gamesShare`
const GAME_PLAY_BETFLIX_URL = `${API_URL}/game/playGameBetflix`
const GAME_PLAY_HUAY14_URL = `${API_URL}/game/playGameHuay14`
const GAME_PLAY_SBOBET_URL = `${API_URL}/game/playGameSbobet`

class GameService {
  beforePlay(id) {
    return axios.post(GAME_BEFOREPLAY_URL+'/'+id,{},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  checkout() {
    return axios.post(GAME_CHECKOUT_URL,{},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  getCredit(id) {
    return axios.get(GAME_GETCREDIT_URL+'/'+id, { headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  getGameList(filename) {
    return axios.get(GAME_LIST_URL+'/'+filename,{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  playGameBetflix(provider, code) {
    return axios.post(GAME_PLAY_BETFLIX_URL,{provider, code},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  playGameHuay14() {
    return axios.get(GAME_PLAY_HUAY14_URL,{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  playGameSbobet(provider, code) {
    return axios.post(GAME_PLAY_SBOBET_URL,{},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
}

export default new GameService();