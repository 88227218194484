import axios from 'axios';
import configData from "../config.json";
import authHeader from './auth-header';

const API_URL = configData.REACT_APP_API_URL;
const REGISTER_URL = `${API_URL}/register`
const PROFILE_URL = `${API_URL}/member/profile`
const CHANGE_PASSWORD_URL = `${API_URL}/member/changePassword`
const FORGOT_PASSWORD_URL = `${API_URL}/member/forgotPassword`

class UserService {
  register(password, firstname, lastname, bank, bankAccountNo, withdrawBank, withdrawBankAccountNo, mobile, howKnow) {
    return axios.post(REGISTER_URL, { password, firstname, lastname, bank, bankAccountNo, withdrawBank, withdrawBankAccountNo, mobile, howKnow })
      .then(response => {
        return response;
      });
  }
  
  changePassword(password) {
    return axios.post(CHANGE_PASSWORD_URL, { password},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  forgotPassword(password, phone, bank, bankAccountNo) {
    return axios.post(FORGOT_PASSWORD_URL, { password, phone, bank, bankAccountNo},{ headers: authHeader() })
      .then(response => {
        return response;
      });
  }
  getProfile() {
    return axios.get(PROFILE_URL, { headers: authHeader() }).then(response => {
      return response;
    });
  }
  
  getUserBoard() {
    return axios.get(API_URL + '/user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + '/mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + '/admin', { headers: authHeader() });
  }
}

export default new UserService();