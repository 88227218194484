import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import clsx from 'clsx'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import BankService from '../services/bank.service'
import UserService from '../services/user.service'
import configData from "../config.json";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const initialValuesStep1 = {
    phone: '',
  }
const initialValuesStep2 = {
    password: '',
  }
  const initialValuesStep3 = {
    bank: '',
    bankId: '',
    bankNo: '',
    bankWithdraw: '',
    bankWithdrawId: '',
    bankWithdrawNo: '',
    firstname: '',
    lastname: '',
    howKnow: ''
  }
const schemaStep1 = Yup.object().shape({
    phone: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "เบอร์มือถือต้องเป็นตัวเลขเท่านั้น"
      )
    .min(10, 'เบอร์มือถือต้องมี 10 ตัว')
    .max(10, 'เบอร์มือถือต้องมี 10 ตัว')
    .required('กรุณาใส่เบอร์มือถือให้ถูกต้อง'),
})
const schemaStep2 = Yup.object().shape({
    password: Yup.string()
    .required('กรุณาใส่รหัสผ่านให้ถูกต้อง'),
})
const schemaStep3 = Yup.object().shape({
    bank: Yup.string()
    .required('กรุณาเลือกธนาคาร'),
    bankNo: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "หมายเลขบัญชีต้องเป็นตัวเลขเท่านั้น"
      )
    .required('กรุณาใส่หมายเลขบัญชี'),
    bankWithdrawNo: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "หมายเลขบัญชีต้องเป็นตัวเลขเท่านั้น"
      ),
    firstname: Yup.string()
    .required('กรุณาใส่ชื่อ'),
    lastname: Yup.string()
    .required('กรุณาใส่นามสกุล'),
})
function Register(){
    
    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [banks, setBanks] = useState([])
    const [selectBank, setSelectBank] = useState(false)
    const [selectBankTrue, setSelectBankTrue] = useState(false)
    const [selectBankWithdraw, setSelectBankWithdraw] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const navigate = useNavigate();
    const options = ['Facebook', 'Google', 'Youtube', 'Line', 'Tiktok'];
    setTimeout(() => {
        setLoading(false);
    }, 700);
    const formikStep1 = useFormik({
        initialValues: initialValuesStep1,
        validationSchema: schemaStep1,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            
            setCurrentStep(2);
        },
    });
    const formikStep2 = useFormik({
        initialValues: initialValuesStep2,
        validationSchema: schemaStep2,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setCurrentStep(3);
        },
    });
    const formikStep3 = useFormik({
        initialValues: initialValuesStep3,
        validationSchema: schemaStep3,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitLoading(true);
            setCurrentStep(4);
            setStatus(null)
            try {
                const password = formikStep2.values.password
                const firstname = values.firstname
                const lastname = values.lastname
                const bank = values.bankId
                const bankAccountNo = values.bankNo
                const withdrawBank = values.bankWithdrawId
                const withdrawBankAccountNo = values.bankWithdrawNo
                const mobile = formikStep1.values.phone
                const howKnow = values.howKnow
                const {data: response} = await UserService.register(password, firstname, lastname, bank, bankAccountNo, withdrawBank, withdrawBankAccountNo, mobile, howKnow)
                if (response.code === 200) {
                    if (response.access_token) {
                        const accessToken = response.access_token;
                        const user = response.data;
                        localStorage.clear();
                        localStorage.setItem("noone-wallet-token", accessToken);
                        localStorage.setItem("noone-wallet-user", JSON.stringify(user));
                        
                        setTimeout(() => {
                            setLoading(false)
                            navigate('/');
                        }, 500);
                    }
                }else{
                    setCurrentStep(3);
                    setSubmitLoading(false);
                    setStatus('เกิดข้อผิดพลาด,'+response.message+' กรุณาลองใหม่อีกครั้ง')
  
                }
            } catch (error) {
                console.error(error)
                setCurrentStep(3);
                setSubmitLoading(false);
                setStatus('เกิดข้อผิดพลาด,'+error.response.data.message+' กรุณาลองใหม่อีกครั้ง')
            }
            
        },
    });
    
    const fetchDataBank = async () => {
        try {
            const {data: dataBanks} = await BankService.get();
            
            setBanks(dataBanks.data);
        } catch (error) {
            console.error(error)
            
        }
    }
    useEffect(() => {
        fetchDataBank()
    }, []);
    const handleOnSelectBank = (bankname, bankid) => {
        formikStep3.setFieldValue('bank',bankname);
        formikStep3.setFieldValue('bankId',bankid);
        setSelectBank(false);
        if(bankid === 39){
            setSelectBankTrue(true);
        }else if(selectBankTrue){
            setSelectBankTrue(false);
        }else{
            formikStep3.setFieldValue('bankWithdraw','');
            formikStep3.setFieldValue('bankWithdrawId','');
            formikStep3.setFieldValue('bankWithdrawNo','');
        }
    }
    const handleOnSelectBankWithdraw = (bankname, bankid) => {
        formikStep3.setFieldValue('bankWithdraw',bankname);
        formikStep3.setFieldValue('bankWithdrawId',bankid);
        setSelectBankWithdraw(false);
        
    }
    return (
        <>
         {loading && (
            <div className="preload">
                <img src={IMAGE_URL+"/logo.webp"} className="img-fluid" alt="logo" width="20%" />
            </div>
          )}

        <div data-aos="fade-left" className="containregister boxcolor aos-init aos-animate">
           
            <div className="logologin">
                <img className="imganimationlogin mt-5" src={IMAGE_URL+"/logo.webp"} alt="" />
            </div>
            <h4 className="text-login text-center mb-3">สมัครสมาชิก</h4>
            <div id="hidefinish" className="mt-4">
                <table style={{width: "100%"}} className="text-center">
                    <tbody>
                    <tr>
                        <td className={clsx(
                                'tdstepregister headstep1',
                                {'active': currentStep === 1 }
                            )}>
                            <b>1</b><br/><span>ยืนยันตัวตน</span>
                        </td>
                        <td className={clsx(
                                'tdstepregister headstep2',
                                {'active': currentStep === 2 }
                            )}>
                            <b>2</b><br/><span>ตั้งรหัสผ่าน</span>
                        </td>
                        <td className={clsx(
                                'tdstepregister headstep3',
                                {'active': currentStep === 3 }
                            )}>
                            <b>3</b><br/><span>บัญชีธนาคาร</span>
                        </td>
                        <td className={clsx(
                                'tdstepregister headstep4',
                                {'active': currentStep === 4 }
                            )}>
                            <b><i className="fal fa-check"></i></b><br/><span>สำเร็จ</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {formikStep3.status && (
                <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formikStep3.status}</div>
                </div>
            )}
            {currentStep === 1 ? (
                <div className="step01 slideto containinlogin">
                    <form id="form_submit_step1" onSubmit={formikStep1.handleSubmit}>
                        <div className="mt-4">
                            <label>เบอร์มือถือ</label>
                            <div className="iconlogin">
                                <i className="fal fa-user"></i>
                            </div>
                            <input type="text" className={
                                formikStep1.errors.phone
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            }  name="phone" id="phone" placeholder="กรอกเบอร์มือถือ" value={formikStep1.values.phone} onChange={formikStep1.handleChange} />
                            
                            {formikStep1.errors.phone ? <label id="phone-error" className="error" htmlFor="phone">{formikStep1.errors.phone}</label> : null}
                        </div>
                        <div className="btnofregister">
                            <table style={{width: "100%"}}>
                                <tbody><tr>
                                    <td className="tdbtnregister">
                                        <button type="button" onClick={()=>{navigate('/');}} className="btnbackregister bkcolor">ย้อนกลับ</button>
                                    </td>
                                    <td className="tdbtnregister">
                                        <button type="submit" id="btn-step1" className="btnnextregister mcolor">ถัดไป</button>
                                    </td>
                                </tr>
                                </tbody></table>
                        </div>
                    </form>
                </div> 
            ) : currentStep === 2 ? (
                <div className="step02 slideto containinlogin" >
                    <form id="form_submit_step2" onSubmit={formikStep2.handleSubmit}>
                        <div style={{marginTop: "30px"}}>
                            <label>รหัสผ่าน</label>
                            <div className="iconlogin">
                                <i className="fal fa-user"></i>
                            </div>
                            <input type="password" className={
                                formikStep2.errors.password
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } name="password" id="password" placeholder="รหัสผ่าน" value={formikStep2.values.password} onChange={formikStep2.handleChange} />
                             {formikStep2.errors.password ? <label id="password-error" className="error" htmlFor="password">{formikStep2.errors.password}</label> : null}
                        </div>
                        <div className="btnofregister">
                            <table style={{width: "100%"}}>
                                <tbody><tr>
                                    <td className="tdbtnregister">
                                        <button type="button" onClick={()=>{setCurrentStep(1);}} id="btn-back1"  className="btnbackregister bkcolor">ย้อนกลับ</button>
                                    </td>
                                    <td className="tdbtnregister">
                                        <button type="submit" id="btn-step2" className="btnnextregister mcolor">ถัดไป</button>
                                    </td>
                                </tr>
                                </tbody></table>
                        </div>
                    </form>
                    
                </div>
            ) : currentStep === 3 ? (
                <div className="step03 slideto containinlogin" >
                    <form id="form_submit_step3" onSubmit={formikStep3.handleSubmit}>
                        <div className="mt-1">
                            <label>ธนาคาร</label>
                            <div className="iconlogin">
                                <i className="fal fa-university"></i>
                            </div>
                            <input type="text" readOnly 
                            className={
                                formikStep3.errors.bank
                                ? "form-control loginform01 open_select_bank error"
                                : "form-control loginform01 open_select_bank"
                            } name="bank" style={{cursor: "pointer"}} id="bank" placeholder="เลือกธนาคาร" value={formikStep3.values.bank} onChange={formikStep3.handleChange} onClick={() => {setSelectBank(true);}} />
                            <input type="hidden" name="financial_service_provider_id" id="financialServiceProviderID" value={formikStep3.values.bankId} onChange={formikStep3.handleChange} />
                            {formikStep3.errors.bank ? <label id="bank-error" className="error" htmlFor="bank">{formikStep3.errors.bank}</label> : null}

                        </div>
                        <div className="mt-2">
                            <label htmlFor="exampleInputEmail1">หมายเลขบัญชี</label>
                            <div className="iconlogin">
                                <i className="fal fa-sort-numeric-up-alt"></i>
                            </div>
                            <input type="text" className={
                                formikStep3.errors.bankNo
                                ? "form-control loginform01 error"
                                : "form-control loginform01"
                            } name="bankNo" id="bankNo" placeholder="กรอกหมายเลขบัญชี" value={formikStep3.values.bankNo} onChange={formikStep3.handleChange}/>
                            {formikStep3.errors.bankNo ? <label id="bankNo-error" className="error" htmlFor="bankNo">{formikStep3.errors.bankNo}</label> : null}
                        </div>
                        <div id="div_widthdraw" style={{display: !selectBankTrue ? "none" : "unset"}}>
                            <div className="mt-1">
                                <label>ธนาคารสำหรับรับเงิน</label>
                                <div className="iconlogin">
                                    <i className="fal fa-university"></i>
                                </div>
                                <input type="text" readOnly className="form-control loginform01 open_select_bank_withdraw" name="bankWithdraw" style={{cursor: "pointer"}} id="bankWithdraw" placeholder="เลือกธนาคาร"
                                value={formikStep3.values.bankWithdraw} onChange={formikStep3.handleChange} onClick={() => {setSelectBankWithdraw(true);}} />
                                <input type="hidden" name="bankWithdrawId" id="bankWithdrawId" 
                                value={formikStep3.values.bankWithdrawId} onChange={formikStep3.handleChange}  />
                                
                            </div>
                            <div className="mt-2">
                                <label htmlFor="exampleInputEmail1">หมายเลขบัญชีสำหรับรับเงิน</label>
                                <div className="iconlogin">
                                    <i className="fal fa-sort-numeric-up-alt"></i>
                                </div>
                                <input type="text" className={
                                    formikStep3.errors.bankWithdrawNo
                                    ? "form-control loginform01 error"
                                    : "form-control loginform01"
                                } name="bankWithdrawNo" id="bankWithdrawNo" placeholder="กรอกหมายเลขบัญชีสำหรับรับเงิน" 
                                value={formikStep3.values.bankWithdrawNo} onChange={formikStep3.handleChange} />
                                {formikStep3.errors.bankWithdrawNo ? <label id="bankWithdrawNo-error" className="error" htmlFor="bankWithdrawNo">{formikStep3.errors.bankWithdrawNo}</label> : null}
                            </div>
                        </div>
                        <div className="mt-1" >
                            <table style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label>ชื่อ</label>
                                            <div className="iconlogin">
                                                <i className="fal fa-user"></i>
                                            </div>
                                            <input type="text" className={
                                                formikStep3.errors.firstname
                                                ? "form-control loginform01 error"
                                                : "form-control loginform01"
                                            } name="firstname" id="firstname" placeholder="กรอกชื่อ" value={formikStep3.values.firstname} onChange={formikStep3.handleChange} />
                                            {formikStep3.errors.firstname ? <label id="firstname-error" className="error" htmlFor="firstname">{formikStep3.errors.firstname}</label> : null}
                                        </td>
                                        <td >
                                            <label>นามสกุล</label>
                                            <input type="text" className={
                                                formikStep3.errors.lastname
                                                ? "form-control loginform01 error"
                                                : "form-control loginform01"
                                            } name="lastname" id="lastname" placeholder="กรอกนามสกุล" value={formikStep3.values.lastname} onChange={formikStep3.handleChange} />
                                            {formikStep3.errors.lastname ? <label id="lastname-error" className="error" htmlFor="lastname">{formikStep3.errors.lastname}</label> : null}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                        <div className="mt-1" >
                            <label htmlFor="">ช่องทางที่รู้จักเรา</label>
                            <select name="howKnow" className="form-control loginform01" id="howKnow" value={formikStep3.values.howKnow} onChange={formikStep3.handleChange}>
                                <option >โปรดเลือก</option>
                                {options.map((option, index) => {
                                    return <option key={index} >
                                        {option}
                                    </option>
                                })}
                                
                            </select>
                            
                            
                        </div>
                        <div className="btnofregister">
                            <table style={{width: "100%"}}>
                                <tbody><tr>
                                    <td className="tdbtnregister">
                                        <button type="button" id="btn-back2" onClick={()=>{setCurrentStep(2);}} className="btnbackregister bkcolor">ย้อนกลับ</button>
                                    </td>
                                    <td className="tdbtnregister">
                                        <button type="submit" id="btn-step3"  className="btnnextregister mcolor">ยืนยัน</button>
                                    </td>
                                </tr>
                                </tbody></table>
                        </div>
                    </form>
                </div> 
            ) : (
                <div className="step04 slideto containinlogin" >
                    <div className="text-center mt-2 mb-1"><i className="far fa-check checkfinish"></i></div>
                    <div className="finishregister" id="step_finishregister">
                        <div style={{display: !submitLoading ? 'none' : 'unset'}}><span>ระบบกำลังดำเนินการ</span> <br/>กรุณารอสักครู่ <i className="fad fa-spinner-third fa-spin "></i></div>
                        
                    </div>
                </div>
            )}
            
        </div>
        <div className="bankselectpopup py-0 px-2" style={{display: !selectBank ? "none" : "unset"}}>
            <div className="inbankselectpopup bkcolor">
                <button className="btnclosebankselect" onClick={() => {setSelectBank(false);}}><i className="fal fa-times"></i></button>
                <table>
                    <tbody id="select_bank">
                    {
                        banks.map(function(bank, i){
                            return <tr key={i}><td><a href="#" onClick={() => {handleOnSelectBank(bank.nameTh,bank.id)}}><img className="selectbank" name={bank.nameTh} atr={bank.id}  src={bank.data.imageUrl} alt="" /><br/> {bank.nameTh} </a></td></tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
       

        <div className="bankselectpopupWithdraw py-0 px-2" style={{display: !selectBankWithdraw ? "none" : "unset"}}>
            <div className="inbankselectpopupWithdraw bkcolor">
                <button className="btnclosebankselectWithdraw" onClick={() => {setSelectBankWithdraw(false);}}><i className="fal fa-times"></i></button>
                <table>
                    <tbody id="select_bank_withdraw">
                    {
                        banks.map(function(bank, i){
                            
                            return (bank.id !== 39 ? <tr key={i}><td><a href="#" onClick={() => {handleOnSelectBankWithdraw(bank.nameTh,bank.id)}}><img className="selectbank" name={bank.nameTh} atr={bank.id}  src={bank.data.imageUrl} alt=""/><br/> {bank.nameTh} </a></td></tr> : null)
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
        
        </>
        
    )
}

export default Register;